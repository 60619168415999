import { CustomerInbox, useMessageInboxes } from '@carnegie/digital-channels-frontend'

type UseInboxesResult = {
  inboxes: CustomerInbox[]
  error?: unknown
  getInboxByPartyId: (partyId: string) => CustomerInbox | undefined
  hasMultipleInboxes: boolean
  hasUnreadThreads: boolean
  defaultInbox: CustomerInbox | undefined
  isLoading: boolean
}

/**
 * A wrapper around useMessageInboxes with some utility properties
 */
export function useInboxes(): UseInboxesResult {
  const { inboxes, error } = useMessageInboxes()

  const hasMultipleInboxes = inboxes?.length > 1
  const hasUnreadThreads = inboxes?.some((inbox) => inbox.unreadCount > 0)
  const defaultInbox = inboxes?.find((inbox) => inbox.defaultInbox)

  const getInboxByPartyId = (partyId: string) => {
    return inboxes?.find((inbox) => inbox.partyId === partyId)
  }

  return {
    inboxes,
    error,
    getInboxByPartyId,
    hasMultipleInboxes,
    hasUnreadThreads,
    defaultInbox,
    isLoading: false,
  }
}
