import { Guid } from '@common/guid'

import { format, isValid } from 'date-fns'
import qs from 'qs'
import queryString from 'query-string'

import { ExportTransactionsRequest } from '../../pages/overview/transactions/ExportTransactionsRequest'
import { TransactionFilterRequest, TransactionRequest } from '../../pages/overview/transactions/TransactionRequest'
import { CorePicksResponse, MoversResponse } from '../../pages/research/shared/corePicks'
import { ResearchFilter } from '../../pages/research/shared/researchFilter'
import { ResearchNewsItem } from '../../pages/research/shared/researchNewsItem'
import { CreateReportBody } from '../components/CreateReportSideDrawer'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { Http } from './http'
import {
  ApiItemResponse,
  ApiItemsResponse,
  ApiResponse,
  AvailableAccountsResponse,
  BaseResponse,
  CancelSimpleOrderRequest,
  CancelSimpleOrderResponse,
  CarnegieInstrument,
  EnvelopeItemsDescriptorOfGetTransferOrdersResponse,
  FundOrderType,
  GetAnalysisRequest,
  GetRelatedInstrumentsResponse,
  GetSettingsResponse,
  GetSubmissionsResponse,
  InfrontInstrument,
  InfrontToken,
  InsertSimpleOrderRequest,
  InsertSimpleOrderResponse,
  InstrumentHoldingsResponse,
  InstrumentNameResponse,
  InstrumentValidityResponse,
  IsLoggedInResponse,
  LoginAssertionResponse,
  OfferingAnswer,
  ProfileResponse,
  RegisterAccountOfferingResponse,
  ResearchNewsResponse,
  ResponsibleResponse,
  SearchInstrumentResponse,
  SystemMessageResponse,
  TransactionResponse,
  TransactionResponseItem,
  TransactionsFilterOptions,
  TransferRequest,
  TransferResponse,
  UpsertSettingsRequest,
  ValidateFundResponse,
  Watchlist,
  WatchlistsResponse,
} from './response'

const DATE_FORMAT_STRING = 'yyyy-MM-dd'
export class Api {
  http: Http

  constructor(http: Http) {
    this.http = http
  }

  async getAvailableAccounts(includeDefaultAccounts = false) {
    const url = `/transfers/available-accounts${includeDefaultAccounts ? '?includeDefaultAccounts=true' : ''}`
    const response = await this.http.get<ApiItemResponse<AvailableAccountsResponse>>(url)
    return response?.item
  }

  async getOngoingTransfers() {
    const response = await this.http.get<EnvelopeItemsDescriptorOfGetTransferOrdersResponse>('/transfers')
    return response?.items
  }

  async registerTransfer(data: TransferRequest) {
    const response = await this.http.post<ApiItemResponse<TransferResponse>>('/transfers', data)
    return response?.item
  }

  cancelTransfer(transferOrderId: number) {
    return this.http.put<BaseResponse>(`/transfers/cancel`, { transferOrderId })
  }

  getUserSetting(key: string) {
    return this.http.get<ApiItemResponse<GetSettingsResponse>>(`/setting/${key}`)
  }

  upsertUserSetting(body: UpsertSettingsRequest) {
    return this.http.putWithoutThrowing<BaseResponse>(`/setting/upsert`, body)
  }

  async registerCorporateActions(body: OfferingAnswer[]) {
    const payload = { offeringAnswers: body }
    const response = await this.http.post<ApiItemResponse<RegisterAccountOfferingResponse>>(
      `/digital-channels/corporate-actions`,
      payload
    )
    return response.item
  }

  insertOrder(body: InsertSimpleOrderRequest) {
    return this.http.post<ApiItemResponse<InsertSimpleOrderResponse>>(
      `/digital-channels/order/simple-order/insert`,
      body
    )
  }

  cancelOrder(body: CancelSimpleOrderRequest) {
    return this.http.put<ApiItemResponse<CancelSimpleOrderResponse>>(
      `/digital-channels/order/simple-order/cancel`,
      body
    )
  }

  async searchInstrument(query: string): Promise<SearchInstrumentResponse> {
    if (!query || query.length < 2) return null
    const url = `/instruments/search?query=${query}&offset=0&limit=10`
    const instrumentsResponse = await this.http.get<ApiItemResponse<SearchInstrumentResponse>>(url)
    return instrumentsResponse?.item
  }

  async validateOrderView(infrontInstrumentIdBase64: string): Promise<InstrumentValidityResponse> {
    const response = await this.http.get<{ item: InstrumentValidityResponse }>(
      `/instruments/validate/${infrontInstrumentIdBase64}/order`
    )
    return response?.item
  }

  async validateInstrumentView(infrontInstrumentIdBase64: string): Promise<InstrumentValidityResponse> {
    const response = await this.http.get<{ item: InstrumentValidityResponse }>(
      `/instruments/validate/${infrontInstrumentIdBase64}`
    )
    return response?.item
  }

  async validateFundView(carnegieInstrumentId: Guid, side: FundOrderType): Promise<ValidateFundResponse> {
    const response = await this.http.get<ApiItemResponse<ValidateFundResponse>>(
      `/digital-channels/order/validate/fund/${carnegieInstrumentId}/${side}`
    )
    return response?.item
  }

  async fetchCarnegieInstrument(infrontInstrumentIdBase64: string, languageCode: string): Promise<CarnegieInstrument> {
    const response = await this.http.get<{ item: CarnegieInstrument }>(
      `/instruments/${infrontInstrumentIdBase64}/${languageCode}`
    )
    return response?.item
  }

  async fetchProfile(): Promise<ProfileResponse> {
    const response = await this.http.get<{ item: ProfileResponse }>(`/party`)
    return response?.item
  }

  async fetchResponsible(): Promise<ResponsibleResponse> {
    const response = await this.http.get<{ item: ResponsibleResponse }>(`/party/responsible`)
    return response?.item
  }

  async fetchInfrontToken(): Promise<InfrontToken> {
    const response = await this.http.get<{ item: InfrontToken }>('/login/infront')
    return response?.item
  }

  async fetchSystemMessage(languageCode = 'sv', route) {
    let url = `/notification/system-messages?languageCode=${languageCode}`
    if (route) url += `&routes=${route}`

    const result = await this.http.get<ApiItemsResponse<SystemMessageResponse>>(url)
    return result?.items
  }

  async fetchContentAnalysisFilter(): Promise<ResearchFilter> {
    const response = await this.http.get<{ item: ResearchFilter }>(`/digital-channels/content/analysis/filter`)
    return response?.item
  }

  createReport(body: CreateReportBody) {
    return this.http.postWithoutThrowing<ApiResponse>(`/reports/generate/`, body)
  }

  async fetchLastBankingDay(date?: Date): Promise<string> {
    const formattedDate = date && isValid(date) ? `?fromDate=${format(date, DATE_FORMAT_STRING)}` : ''
    const lastBankingDay = await this.http.get<string>(`/holdings/last-banking-day${formattedDate}`)

    return lastBankingDay
  }

  async fetchPositions(): Promise<InstrumentIdContainer[]> {
    const response = await this.http.get<{ items: InstrumentIdContainer[] }>('/holdings')
    return response.items
  }

  async fetchInstrumentHoldings(infrontInstrumentIdBase64: string): Promise<InstrumentHoldingsResponse[]> {
    const response = await this.http.get<{ items: InstrumentHoldingsResponse[] }>(
      `/holdings/${infrontInstrumentIdBase64}`
    )

    return response.items
  }

  fetchInstrumentName(instrumentId: string): Promise<InstrumentNameResponse> {
    return this.http.get<InstrumentNameResponse>(`/instruments/${instrumentId}`)
  }

  fetchTransactions({
    accountIds,
    currencies,
    fromDate,
    instruments,
    language,
    size,
    start,
    toDate,
    transactionTypes,
  }: TransactionRequest): Promise<TransactionResponse> {
    const request = {
      accountIds,
      currencies,
      instruments,
      language,
      size,
      start,
      transactionTypes,
      fromDate: fromDate && isValid(fromDate) && format(fromDate, DATE_FORMAT_STRING),
      toDate: toDate && isValid(toDate) && format(toDate, DATE_FORMAT_STRING),
    }
    const params = queryString.stringify(request) //making query-params
    return this.http.get(`/transactions?${params}`)
  }

  async fetchPreliminaryTransactions(accountId: string, languageCode: string): Promise<TransactionResponseItem> {
    const response = await this.http.get<{ item: TransactionResponseItem }>(
      `/transactions/preliminary/${accountId}/${languageCode}`
    )
    return response?.item
  }

  async fetchClientContracts(): Promise<GetSubmissionsResponse> {
    const response = await this.http.get<{ item: GetSubmissionsResponse }>('/client-contracts')
    return response?.item
  }

  async fetchClientContractsRedirect(baseUrl: string): Promise<string> {
    const response = await this.http.get<{ item: string }>(
      `/client-contracts/redirect/?url=${encodeURIComponent(baseUrl)}`
    )
    return response?.item
  }

  fetchWatchlists() {
    return this.http.get<ApiItemResponse<WatchlistsResponse>>('/watchlists')
  }

  createWatchlist(name: string) {
    return this.http.postWithoutThrowing<ApiItemResponse<Watchlist>>('/watchlists', { name })
  }

  updateWatchlist(id: string, name: string) {
    return this.http.putWithoutThrowing<ApiItemResponse<Watchlist>>(`/watchlists/${id}`, { id, name })
  }

  deleteWatchlist(id: string) {
    return this.http.deleteWithoutThrowing<ApiResponse>(`/watchlists/${id}`)
  }

  addWatchlistItem(id: string, instrumentId: string) {
    return this.http.postWithoutThrowing('/watchlists/add', { id, instrumentId })
  }

  deleteWatchlistItem(id: string, instrumentId: string) {
    return this.http.deleteWithoutThrowing<BaseResponse>(`/watchlists/${id}/instruments/${instrumentId}`)
  }

  fetchTransactionsFilter(body: TransactionFilterRequest) {
    return this.http.postWithoutThrowing<ApiItemResponse<TransactionsFilterOptions>>('/transactions/filters', body)
  }

  fetchContentAnalysis(request: GetAnalysisRequest) {
    const params = qs.stringify(request, { skipNulls: true, allowDots: true })
    return this.http.get<ApiItemResponse<ResearchNewsResponse>>(`/digital-channels/content/analysis?${params}`)
  }

  fetchResearchNewsItem(id: string) {
    return this.http.get<ApiItemResponse<ResearchNewsItem>>(`/digital-channels/content/analysis/${id}`)
  }

  fetchContentRelatedInstrument(companyId: number, onlyFirstPrimaryInstrument = true) {
    return this.http.get<ApiItemResponse<GetRelatedInstrumentsResponse>>(
      `/digital-channels/content/analysis/${companyId}/related-instruments/${onlyFirstPrimaryInstrument ? '' : 'false'}`
    )
  }

  fetchAnalysisMovers() {
    return this.http.get<ApiItemResponse<MoversResponse>>(`/digital-channels/content/analysis/movers/`)
  }

  fetchAnalysisCorePicks() {
    return this.http.get<ApiItemResponse<CorePicksResponse>>(`/digital-channels/content/analysis/corepicks/`)
  }

  getCarnegieInstrumentIdFromInfront(feed: number, ticker: string) {
    return this.http.get<ApiItemResponse<Guid>>(`/instruments/exchange/infront/${feed}/${ticker}`)
  }

  getInfrontInstrumentFromCarnegieId(carnegieInstrumentId: Guid) {
    return this.http.get<ApiItemResponse<InfrontInstrument>>(`/instruments/exchange/infront/${carnegieInstrumentId}`)
  }

  exportHoldingsUrl(id: string, languageCode: string): string {
    return `/api/accounts/${id}/${languageCode}/export-file`
  }

  exportTransactionsUrl({
    fromDate,
    toDate,
    language,
    selectedAccount,
    totalTransactionsCount,
    currencies,
    instruments,
    transactionTypes,
  }: ExportTransactionsRequest): string {
    const request = {
      fromdate: fromDate && isValid(fromDate) && format(fromDate, DATE_FORMAT_STRING),
      toDate: toDate && isValid(toDate) && format(toDate, DATE_FORMAT_STRING),
      language,
      selectedAccount,
      totalTransactionsCount,
      currencies,
      instruments,
      transactionTypes,
    }
    const params = qs.stringify(request) //making query-params
    return `/api/transactions/export-file?${params}`
  }

  logout() {
    return this.http.get<ApiResponse>('/login/logout')
  }

  loginAssertion(token: string) {
    return this.http.post<ApiItemResponse<LoginAssertionResponse>>('/login/assertion', { token })
  }

  jwtAssertion(assertionToken: string) {
    return this.http.post<ApiResponse>('/oauth-agent/login/jwt-assertion', { assertionToken })
  }

  isLoggedIn() {
    return this.http.getWithoutThrowing<ApiItemResponse<IsLoggedInResponse>>('/login/is-logged-in')
  }
}
