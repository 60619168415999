import { useHttp } from '@common/stores/store'

import {
  AvatarResponse,
  CustomerInbox,
  MessageSettings,
  MessagesService,
  PossibleSubscribers,
  SentMessagesResponse,
  ThreadResponse,
  ThreadsResponse,
  UploadAttachmentResponse,
} from '@carnegie/digital-channels-frontend'

// Implements the backend part of the secure messages hooks located in pbo-shared
export function useMessagesService(): MessagesService {
  const http = useHttp()

  return {
    createNewMessage: async (newMessage) => {
      return await http.post(`/communication/message/new`, newMessage)
    },
    createThreadMessage: async (replyMessage) => {
      return await http.post(`/communication/message/reply`, replyMessage)
    },
    setThreadAsRead: async (threadId, trusteeOverride) => {
      await http.put(
        `/communication/threads/${threadId}/read` + (trusteeOverride ? `?trusteeOverride=${trusteeOverride}` : ''),
        undefined
      )
    },
    setThreadAsUnread: async (threadId, trusteeOverride) => {
      await http.get(
        `/communication/thread/${threadId}/unread` + (trusteeOverride ? `?trusteeOverride=${trusteeOverride}` : '')
      )
    },
    getThread: (threadId, trusteeOverride) => {
      return http.get<ThreadResponse>(
        `/communication/threads/${threadId}` + (trusteeOverride ? `?trusteeOverride=${trusteeOverride}` : '')
      )
    },
    getPossibleSubscribers: (trusteeOverride) => {
      return http.get<{ item: PossibleSubscribers }>(
        // For some reason the backend calls this overrideId and not trusteeOverride should perhaps be fixed
        `/communication/subscriber/possible` + (trusteeOverride ? `?overrideId=${trusteeOverride}` : '')
      )
    },
    getSentMessages: (pageNumber, pageSize, trusteeOverride) => {
      return http.get<SentMessagesResponse>(
        `/communication/messages/sent?pageSize=${pageSize}&page=${pageNumber}${
          trusteeOverride ? `&trusteeOverride=${trusteeOverride}` : ''
        }`
      )
    },
    getThreads: async (pageNumber, pageSize, canReply, trusteeOverride) => {
      const result = await http.get<ThreadsResponse>(
        `/communication/threads?pageSize=${pageSize}&page=${pageNumber}&canReply=${canReply}${
          trusteeOverride ? `&trusteeOverride=${trusteeOverride}` : ''
        }`
      )

      return result
    },
    getMessageSettings: async (trusteeOverride) => {
      return http.get<{ item: MessageSettings }>(
        '/communication/subscriber/settings' + (trusteeOverride ? `?trusteeOverride=${trusteeOverride}` : '')
      )
    },
    setMessageSettings: async (settings) => {
      await http.put('/communication/subscriber/settings', settings)
    },
    uploadMessageAttachments: async (formData) => {
      return http.postFormData<UploadAttachmentResponse>('/communication/attachment', formData)
    },
    getAvatar: (partyId, size) => {
      return http.get<AvatarResponse>(`/communication/avatar?partyId=${partyId}&avatarSize=${size}`)
    },
    getInboxes: () => {
      return http.get<{ items: CustomerInbox[] }>('/communication/inboxes')
    },
    getUnreadThreadsCount: (trusteeOverride) => {
      return http.get(
        `/communication/unread-threads-count` + (trusteeOverride ? `?trusteeOverride=${trusteeOverride}` : '')
      )
    },
  }
}
