import React, { useEffect } from 'react'

/**
 * This component is used to test the error boundary.
 * Use the urL: /test-error
 */

const TestErrorThrower: React.FC = () => {
  useEffect(() => {
    throw new Error('This is a test error')
  }, [])

  return <div>This text won't be rendered</div>
}

export default TestErrorThrower
