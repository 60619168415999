import useSWR from 'swr'
import { useApi } from '../stores/store'

export function useProfile() {
  const api = useApi()

  const { data: profile, error } = useSWR('profile', () => api.fetchProfile())

  return { profile, error }
}
