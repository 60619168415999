import { AuthStore as CurityAuthStore } from '@carnegie/curity-auth'
import { isDevMode } from '@common/hooks/useFeatures'
import { trackException } from '@common/utils/analyticsEvent'
import { getImpersonationKeyValuesFromSearchParams } from '@common/utils/impersonation'
import { action, makeObservable, observable } from 'mobx'
import i18n from '../../i18n'
import { Api } from '../api/api'
import { CustomerPermissionFlags } from '../api/response'
import { InfrontStore } from './infrontStore'
import { NavigationStore } from './navigationStore'
import { WatchlistStore } from './watchlistStore'

export class AuthStore {
  api: Api
  infrontStore: InfrontStore
  watchlistStore: WatchlistStore
  navigationStore: NavigationStore
  isAuthenticating: boolean = true
  forcedCarnegieToken: string
  impersonationViaUrlParamsInitiated: boolean = false // Initialization of impersonation will take some time, so we control the UI with this flag, and can decide to not show content until the impersonation has completed

  /** **Important!** Do not modify directly, use setIsAuthenticated method */
  isAuthenticated: boolean = false
  isImpersonated: boolean = false
  hasLimitedView: boolean = false
  customerPermissionFlags: CustomerPermissionFlags
  roles: string[]
  userPrefix: string

  setCurityAuthStore(curityAuthStore: CurityAuthStore) {
    this.curityAuthStore = curityAuthStore
  }

  private curityAuthStore: CurityAuthStore = undefined

  constructor(api: Api, infrontStore: InfrontStore, watchlistStore: WatchlistStore, navigationStore: NavigationStore) {
    makeObservable(this, {
      isAuthenticating: observable,
      isAuthenticated: observable,
      isImpersonated: observable,
      hasLimitedView: observable,
      roles: observable,
      userPrefix: observable,
      checkAuthentication: action,
      logout: action,
      checkIfLoggedIn: action,
      customerPermissionFlags: observable,
    })

    this.api = api
    this.infrontStore = infrontStore
    this.watchlistStore = watchlistStore
    this.navigationStore = navigationStore
  }

  private setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated
  }

  async checkAuthentication(retry = true) {
    const response = await this.api.isLoggedIn()
    const success = !response.error
    const isLoggedIn = success && Boolean(response.item?.isLoggedIn)

    if (!isLoggedIn && retry) {
      await this.refresh()
      return this.checkAuthentication(false)
    }

    if (success) {
      const authFlowPreferredLang = this.curityAuthStore?.user?.locale
      if (authFlowPreferredLang && !localStorage.getItem('browserStorageLang')) {
        i18n.changeLanguage(authFlowPreferredLang)
      }

      this.userPrefix = response.item.userPrefix
      this.isImpersonated = Boolean(response.item?.isImpersonated)
      this.hasLimitedView = Boolean(response.item?.hasLimitedView)
      this.customerPermissionFlags = {
        hasAnalysisPackage: Boolean(response.item?.customerPermissionFlags?.hasAnalysisPackage),
        hasReach: Boolean(response.item?.customerPermissionFlags?.hasReach),
      }
    }

    if (isLoggedIn) {
      // Here we can initialize customer data, infront etc
      this.infrontStore.init()
      this.watchlistStore.init(this.isImpersonated)
    }

    this.setIsAuthenticated(isLoggedIn)
    return { success, isLoggedIn }
  }

  /**
   * Will log out from all services like our BFF Api, infront, curity etc.
   * ⚠️ Only to be called from the logout page, do not call directly as you should always redirect to the
   * logout page first. The reason for this is we want to make sure all components are unmounted first.
   */
  async finalizeLogout() {
    // In some cases the logout can cause crashes (from Infront) so we need to catch it so our own logic can continue
    if (this.infrontStore.hasLoaded) {
      try {
        this.infrontStore.logout()
      } catch (err) {
        trackException(err, 'finalizeLogout')
        console.error('An error occurred when logging out infront', err)
      }
    }

    await this.api.logout()

    if (isDevMode()) {
      await this.curityAuthStore.logout(true) //disable redirect
      window.location.reload()
    } else {
      // This will also redirect back to login page
      await this.curityAuthStore.logout()
    }
  }

  /**
   * Start the logout process by redirecting to the logout page, the logout page will in turn finalize the logout after all components have been unmounted.
   */
  logout() {
    this.navigationStore.push('/logout')
  }

  async checkImpersonationViaURLParams() {
    if (!window.location.href.match(/[?|&]act-as=/g)) return

    const extraParams = getImpersonationKeyValuesFromSearchParams()
    window.history.replaceState(null, null, window.location.pathname)

    this.impersonationViaUrlParamsInitiated = true // Since startLogin will take some time, we control the UI with this flag, and can decide to not show content until startLogin has completed
    await this.curityAuthStore.startLogin({ extraParams })
    this.impersonationViaUrlParamsInitiated = false
  }

  async checkIfLoggedIn() {
    this.isAuthenticating = true
    const { success } = await this.checkAuthentication()
    if (success) this.isAuthenticating = false
  }

  async refresh() {
    try {
      await this.curityAuthStore.refresh()
    } catch (err) {
      trackException(err, 'CurityRefreshFailed')
      console.log(err)
    }
  }

  setForbiddenResource(url: string) {
    this.curityAuthStore.setForbiddenResource(url)
  }
}
